import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import {
  LoginCallback,
  SecureRoute,
  Security,
  useOktaAuth,
} from "@okta/okta-react";
import React from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import AuthenticatedApp from "./components/AuthenticatedApp";

const config = {
  clientId: "0oa3d5l22opQTbtUO5d7",
  issuer: "https://dev-56199969.okta.com/oauth2/default",
  redirectUri: "https://tigermerchantservices.com/login/callback",
  scopes: ["openid", "profile", "email", "groups"],
  pkce: true,
};

const oktaAuth = new OktaAuth(config);

function HomeScreen() {
  const { authState, oktaAuth } = useOktaAuth();

  const login = () => oktaAuth.signInWithRedirect({ originalUri: "/" });

  if (!authState) {
    return <div>Loading authentication...</div>;
  } else if (!authState.isAuthenticated) {
    //take user to sign in widget
    login();
    return;
  } else {
    return <Redirect to="/portal" />;
  }
}

function AppWithRouterAccess() {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <SecureRoute path="/portal">
          <AuthenticatedApp />
        </SecureRoute>
        <Route exact path="/login/callback" component={LoginCallback} />
      </Switch>
    </Security>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppWithRouterAccess />
    </BrowserRouter>
  );
}

export default App;
