import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { createJWT } from "../utils/createJWT";
import { getDeals } from "../utils/getDeals";
import getTableauAuth from "../utils/getTableauAuth";
import DealSelect from "./DealSelect";
import SideNav from "./SideNav";
import Viz from "./Viz";

export default function AuthenticatedApp() {
  const { oktaAuth } = useOktaAuth();
  const [vizLink, setVizLink] = useState("");
  const [token, setToken] = useState(createJWT());
  const [deals, setDeals] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState({});
  const [status, setStatus] = useState("LOADING");
  const [isAdmin, setIsAdmin] = useState(false);
  const [tab, setTab] = useState("");
  

  //refresh the token
  useEffect(() => {
    const interval = setInterval(async () => {
      let token = await createJWT(oktaAuth);
      setToken(token);
    }, [240000]);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getUserGroups() {
      try {
        let token = await createJWT(oktaAuth);
        setToken(token);

        const { auth, siteID } = await getTableauAuth(oktaAuth);

        const { email: userEmail, groups } = await oktaAuth.getUser();


        //grab the second group in the lust
        if (groups[1].includes("Tiger")) {
          setIsAdmin(true);

          const selectableDeals = await getDeals(
            true,
            auth,
            siteID,
            userEmail,
            groups[1]
          );

          setTab("DealSummary");
          setDeals(selectableDeals);
          setSelectedDeal(selectableDeals[0]);

          setVizLink(
            `https://us-east-1.online.tableau.com/t/tcgdashboard/views/${selectableDeals[0].link}/DealSummary?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link`
          );
        } else {
          const selectableDeals = await getDeals(
            true,
            auth,
            siteID,
            userEmail,
            groups[1]
          );

          setTab("DealSummary");
          setDeals(selectableDeals);
          setSelectedDeal(selectableDeals[0]);

          setVizLink(
            `https://us-east-1.online.tableau.com/t/tcgdashboard/views/${selectableDeals[0].link}/DealSummary?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link`
          );
        }

        setStatus("RESOLVED");
      } catch (error) {
        console.log(error);
      }
    }

    getUserGroups();
  }, [oktaAuth]);

  function handleClick(to) {
    setTab(to);
    setVizLink(
      `https://us-east-1.online.tableau.com/t/tcgdashboard/views/${selectedDeal.link}/${to}?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link`
    );
  }

  function handleDealSelection(e) {
    setVizLink(
      `https://us-east-1.online.tableau.com/t/tcgdashboard/views/${e.link}/${tab}?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link`
    );

    setSelectedDeal(e);
  }

  return (
    <div className="flex h-screen">
      <div className="w-2/12">
        <SideNav
          handleClick={handleClick}
          vizLink={vizLink}
          oktaAuth={oktaAuth}
        />
      </div>
      <div className="flex-grow">
        {isAdmin && status === "LOADING" && (
          <div className="flex justify-center items-center">
            <svg
              className={`block animate-spin mx-auto h-8 w-8 text-red-300`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        )}
        {isAdmin && status === "RESOLVED" && (
          <div className="w-2/12">
            <DealSelect
              deals={deals}
              setSelectedDeal={handleDealSelection}
              selectedDeal={selectedDeal}
            />
          </div>
        )}
        <Viz vizLink={vizLink} token={token} />
      </div>
    </div>
  );
}
