import axios from "axios";

const apiDomain = 'https://lucasplaceholder.xyz/159.223.178.100/';

export default async function getTableauAuth(oktaAuth) {
    if (oktaAuth === undefined) {
        return;
    }

    let oktaToken = oktaAuth.getAccessToken()

    let {
        data: { auth, siteID }
    } = await axios.get(
        `${apiDomain}api/v1/tableau/auth?token=${oktaToken}`
    );

    return {
        auth: auth,
        siteID: siteID,
    };
}
