import axios from "axios";

const apiDomain = 'https://lucasplaceholder.xyz/159.223.178.100/';

export async function createJWT(oktaAuth) {
    if (oktaAuth === undefined) {
        return;
    }

    let oktaToken = oktaAuth.getAccessToken()

    let {
        data: { token }
    } = await axios.get(
        `${apiDomain}api/v1/tableau/tokenMerchant?token=${oktaToken}`
    );

    return token;
}
