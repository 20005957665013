import React from "react";
import logo from "../images/tigerWhite.jpg";

const navigation = [
  { name: "Deal Summary", to: "DealSummary" },
  { name: "Daily Sales Detail", to: "DailySalesDetail" },
  { name: "Sell Through & Discount", to: "SellThroughDiscount" },
  { name: "Location Overview", to: "LocationOverview" },
  { name: "Per Store Contribution", to: "PerStoreContribution" },
  { name: "Overview of Categories", to: "OverviewofCategories" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideNav({ vizLink, handleClick, oktaAuth }) {
  return (
    <div className="h-full flex flex-col w-56 border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
      <div className="flex items-center flex-shrink-0 px-4">
        <img className="h-8 w-auto mx-auto" src={logo} alt="Tiger group" />
      </div>
      <div className="mt-5 flex flex-col">
        <nav className="flex-1 px-2 bg-white space-y-1" aria-label="Sidebar">
          {navigation.map((item) => (
            <button
              key={item.name}
              onClick={() => handleClick(item.to)}
              className={classNames(
                vizLink.includes(item.to)
                  ? "bg-gray-100 text-gray-900"
                  : "text-gray-600 hover:bg-gray-100 hover:text-gray-900",
                "w-full group flex items-center px-2 py-2 font-medium rounded-md"
              )}
            >
              <span className="flex-1">{item.name}</span>
            </button>
          ))}

          <a
            className={classNames(
              "block px-4 py-2 text-sm font-medium text-indigo-500 text-center"
            )}
            target="_blank"
            rel="noreferrer noopener"
            href="https://dev-56199969.okta.com/enduser/settings"
          >
            Account Settings
          </a>

          <button
            type="submit"
            onClick={() => oktaAuth.signOut()}
            className={classNames(
              "block w-full px-4 py-2 text-sm font-medium text-indigo-500 text-center"
            )}
          >
            Sign Out
          </button>
        </nav>
      </div>
    </div>
  );
}
