import axios from "axios";

async function getTableauUserID(auth, group, siteID, userEmail) {
  try {
    console.log(group);

    const {
      data: {
        users: { user },
      },
    } = await axios.get(
      `https://lucasplaceholder.xyz/https://us-east-1.online.tableau.com/api/3.13/sites/${siteID}/groups/${
        group.split("-identifier")[1]
      }/users`,
      {
        headers: {
          "X-Tableau-Auth": auth,
        },
      }
    );
    console.log(user);
    console.log(userEmail);
    //get the matching user and pluck their id
    const [{ id: tableauUserID }] = user.filter((e) => e.name === userEmail);
    return tableauUserID;
  } catch (error) {
    throw error;
  }
}

async function getWorkbooks(auth, siteID, tableauUserID) {
  try {
    const {
      data: {
        pagination,
        workbooks: { workbook },
      },
    } = await axios.get(
      `https://lucasplaceholder.xyz/https://us-east-1.online.tableau.com/api/3.13/sites/${siteID}/users/${tableauUserID}/workbooks`,
      {
        headers: {
          "X-Tableau-Auth": auth,
        },
      }
    );

    const localWorkBooks = workbook;

    let currentWorkBooksTotal = Number(pagination.pageSize);
    let totalAvailable = Number(pagination.totalAvailable);
    let pageNumber = 2;

    while (currentWorkBooksTotal < totalAvailable) {
      //there are more workbooks to grab
      const {
        data: {
          workbooks: { workbook },
        },
      } = await axios.get(
        `https://lucasplaceholder.xyz/https://us-east-1.online.tableau.com/api/3.13/sites/${siteID}/users/${tableauUserID}/workbooks?pageNumber=${pageNumber}`,
        {
          headers: {
            "X-Tableau-Auth": auth,
          },
        }
      );

      localWorkBooks.push(...workbook);
      pageNumber++;
      currentWorkBooksTotal += workbook.length;
    }

    return localWorkBooks;
  } catch (error) {
    throw error;
  }
}

export async function getDeals(isTiger, auth, siteID, userEmail, group) {
  //get user id
  const tableauUserID = await getTableauUserID(auth, group, siteID, userEmail);

  //get workbooks for use
  const localWorkBooks = await getWorkbooks(auth, siteID, tableauUserID);

  if (isTiger) {
    //filter out unwanted deals
    const selectableDeals = [];
    for (const w of localWorkBooks) {
      if (
        w.project &&
        w.project.name === "Liquidation Dashboards" &&
        w.name !== "!Liquidation Template"
      ) {
        selectableDeals.push({
          id: w.project.id,
          link: w.contentUrl,
          name: w.name,
        });
      }
    }
    return selectableDeals;
  } else {
    //only need their content url

    const selectableDeals = [];
    for (const w of localWorkBooks) {
      if (
        w.project &&
        w.project.name === "Liquidation Dashboards" &&
        w.name !== "!Liquidation Template" &&
        w.contentUrl &&
        w.contentUrl.includes(group)
      ) {
        selectableDeals.push({
          id: w.project.id,
          link: w.contentUrl,
          name: w.name,
        });
      }
    }
    return selectableDeals;
  }
}
